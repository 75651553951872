/*eslint-disable*/


import Vue from 'vue'
// 引入vueRouter
import VueRouter from 'vue-router'
// 引入文件
import router from './router'
// 引入Vuex 
// import Vuex from 'vuex'
// Vue.use(Vuex)  文件执行问题
// 在Store文件引入Vuex

// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';

// 引入Element-UI
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import store from "./store"
import axios from "axios";
import request from "./request/request";
import VueI18n from 'vue-i18n'

import zh from './language/zh'
import en from './language/en'
import portugal from './language/portugal'


import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(VueRouter)
// Vue.use(ElementUI)
Vue.use(Vant);
Vue.use(VueI18n)

Vue.prototype.$Host = request.HOST
Vue.prototype.$IMGURL = request.IMGURL
Vue.prototype.$axios = axios
// 事件总线
Vue.prototype.$EventBus = new Vue();


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDE3pc9A4C3ukuQkXkcitYHY51rdTrmM1Y",
  authDomain: "top-75f33.firebaseapp.com",
  projectId: "top-75f33",
  storageBucket: "top-75f33.firebasestorage.app",
  messagingSenderId: "677048456263",
  appId: "1:677048456263:web:f220f98aae4597a22a4d62",
  measurementId: "G-8464L1GS2J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

gtag('config', 'G-12345ABCDE', { 'debug_mode': true });


const languagePackage = {
  en: {
    ...en // 将自定义语言包加入
  },
  zh: {
    ...zh // 将自定义语言包加入
  },
  pt: {
    ...portugal // 将自定义语言包加入
  },
}

// const i18n = new VueI18n({

//   locale: 'en', // 设置地区
//   messages // 设置地区信息
// })

const i18n = new VueI18n({
  locale: (function () {
    if (localStorage.getItem('7topgame.com-user-lang')) {
      return localStorage.getItem('7topgame.com-user-lang')
    }
    return 'en'
  }()),
  messages: languagePackage,
  silentTranslationWarn: true //关闭警告
})




new Vue({
  render: h => h(App),
  store,
  i18n,
  router: router
}).$mount('#app')
