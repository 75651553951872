import Mini80 from '../components/home/Mini80.vue'
import Vegasjackpots777 from '../components/home/Vegasjackpots777.vue'
import Happysky from '../components/home/Happysky.vue'
import Profile from '../components/profile/Profile.vue'
// import Slideshow from '../components/Slideshow.vue'
// import Nav from '../views/Nav.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import UserAction from '../views/UserAction.vue'
import AllGameByClassify from '../views/AllGameByClassify.vue'
import AdIframeWindows from '../components/iframe/AdIframeWindows.vue'
import GameIframeWindowns from '../components/iframe/GameIframeWindowns.vue'
import store from '../store/index'


import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Main,
            // 重定向
            redirect: '/mini80/menu',
            // redirect: '/home/menu',
            children: [
                { path: '/mini80/menu', component: Mini80 },
                { path: '/vegasjackpots777/menu', component: Vegasjackpots777 },
                { path: '/happysky/menu', component: Happysky },
                { path: '/profile/info', component: Profile },
            ],
        },
        { path: '/start/ad', component: AdIframeWindows },
        { path: '/start/game', component: GameIframeWindowns },
        // {
        //     path: '/login', component: Login
        // },
        // {
        //     path: '/register', component: Register
        // },
        // {
        //     path: '/user/action', component: UserAction
        // },
        {
            path: '/all/games', component: AllGameByClassify
        }
    ]
})

// router.beforeEach((to, from, next) => {
//     // const [navigation] = performance.getEntriesByType("navigation");
//     // console.log(from.name === null, to);
//     if (store.state.adIframeInfo.isShowWindows || store.state.gameIframeGameInfo.isShowWindows) {
//         if (!(from.name === null)) {
//             let info = {
//                 isShowWindows: false
//             }
//             store.commit('changeAdIframeData', info)
//             store.commit('changeIframeData', info)
//             next(from.path)
//             return
//         }
//         next();
//     } else {
//         next();
//     }
// });

export default router;
