<template>
  <div>

    <router-view></router-view>

  </div>
</template>

<script>
import request from "./request/request";

export default {
  data() {
    return {
      isInstall: this.$store.state.isInstallApp
    }
  },

  watch: {
    $route: function (to, from) {

    },
  },

  components: {
  },

  created() {

  },

  methods: {

    showInstallTip(e) {
      this.$store.commit("showInstallTip", e);
    },

    webSocketInit() {
      let userInfo = localStorage.getItem("7topgame.com-user-info");
      if (userInfo == '{}' || userInfo == null) {
        return;
      }
      let uid = JSON.parse(userInfo).id;
      const socket = new WebSocket(request.WEBSOCKET + uid);

      socket.onopen = function (event) {
        console.log("WebSocket connected");
      };

      socket.onmessage = function (event) {
        console.log("receive:", event);
      };

      socket.onclose = function (event) {
        console.log("WebSocket offline");
      };

      socket.onerror = function (error) {
        console.error("WebSocket err:", error);
      };
      return socket
    },

    initialApp(e) {
      this.$store.commit("initialApp", e);
    },

    // 判断是否在pwa内
    isPWA() {
      const displayModes = ['fullscreen', 'standalone', 'minimal-ui']
      const matchesPwa = displayModes.some(
        displayMode => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
      return (
        matchesPwa ||
        window.navigator?.standalone ||
        document.referrer.includes('android-app://')
      )
    },

    handleCurrentPageChange() {
      let height = window.innerHeight;
      let width = window.innerWidth;
      this.pageHeight = height + "px";
      this.pageWidth = width + "px";
      // console.log(this.pageWidth, this.pageHeight);
    },

  },

  mounted() {
    let that = this
    window.addEventListener('beforeinstallprompt', function (e) {
      that.initialApp(e)
      if (!that.isPWA()) {
        if (e != null) {
          that.showInstallTip(true)
        }
      }
    });

    this.webSocketInit();
    setInterval(() => {
      this.webSocketInit();
    }, 50000);

    window.addEventListener('resize', () => {
      this.handleCurrentPageChange()
    });

  },

}
</script>

<style scoped></style>
