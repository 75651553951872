import http from "./http";

const api = {

    delUserCollect(params) {
        return http.post('/client/collect/del', params);
    },

    userCollectGame(params) {
        return http.post('/client/collect/insert', params);
    },

    findAllGames(params) {
        return http.get(`/client/game/findAll?lang=${params.lang}&sourceId=${params.sourceId}`);
    },

    initializeClassification(params) {
        return http.get(`/client/gameClassify/findByLanguage?l=${params}`);
    },

    initializeSource(params) {
        return http.get('/client/partner/findAll');
    },

    recordUserPlayGameTime(params) {
        return http.post('/common/playGameTimeRecord/recordUserPlayGameTime', params);
    },

    sendEmailCheckCode(params) {
        return http.get(`/client/user/sendEmailCheckCode?email=${params}`);
    },

    signInByGoogle(params) {
        return http.get(`/client/user/signInWithGoogle`);
    },

    quicklyLogin(params) {
        return http.post('/client/user/quicklyLogin', params);
    },

    updateNickname(params) {
        return http.post('/client/user/updateByName', params);
    },

    getRankLists(params) {
        return http.get(`/client/rankList/getRankLists?lang=${params}&sourceId=${params}`);
    },

    uploadUserHeadPhoto(params) {
        return http.post('/client/tourist/upload', params);
    },

    openGame(params) {
        return http.get(`/client/game/openGame?id=${params}`);
    },


    findRankListByLang(params) {
        return http.get(`/client/rankList/findByLangSortByGameId?lang=${params.lang}&sourceId=${params.sourceId}`);
    },

    findProfileRankListByLang(params) {
        return http.get(`/client/rankList/getRankLists?lang=${params.lang}&sourceId=${params.sourceId}`);
    },

    getSlideshowByStatus(params) {
        return http.get(`/client/slideshow/findByStatus?sourceId=${params}`);
    },

    getUserFavorite(params) {
        return http.get(`/client/collect/findByTid?tid=${params}`);
    },

    findGameByPage(params) {
        return http.post('/client/game/findByPage', params);
    },

    checkGoogleIsLogin(params) {
        return http.get(`/client/user/signInById?originEncrypt=${params}`);
    },

    generateTourist(params) {
        return http.post('/client/tourist/signInWithTourist', params);
    },

    visitSite(params) {
        return http.get(`/common/browsingRecord/insert?nums=1&tid=${params}`);
    },

    modifyManifastJson(params) {
        return http.get(`/client/partner/generateManifest?path=${params}`);
    },

    getAd(params) {
        return http.get(`/common/ad/findByStatus?sourceId=${params}`);
    },
};


export default api;