import axios from 'axios';

// axios.defaults.headers.common['referer'] = 'https://www.7topgame.com/'

const instance = axios.create({
    baseURL: 'https://back.7topgame.com',
    // baseURL: 'http://localhost:10002',
    timeout: 15000,
});
// 请求拦截器
instance.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 响应拦截器
instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
);
// 封装HTTP请求方法
const http = {
    get(url, params) {
        return instance.get(url, { params });
    },
    post(url, data) {
        return instance.post(url, data);
    },
    put(url, data) {
        return instance.put(url, data);
    },
    delete(url) {
        return instance.delete(url);
    },
};
export default http;

