<template>
  <div class="rankListComponentBox">
    <div class="rankListSlider">
      <div class="rankListSlide" v-for="(item, index) in rankListData" @click="toGame(item)">
        <span class="rankListBonusInfoItemText">
          <img style="margin: 0 5px 0 0;width: 18px; vertical-align: middle;text-align: center;"
            src="../../assets/sound.png" alt="">
          {{ $t("common.home.congratulations") }}
          <span style="color: #27b507;"> {{ item.username }}</span>
          {{ item.description }}
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapState, mapActions } from "vuex";
import api from "../../request/api";
export default {
  data() {
    return {
      rankListData: [],
      game: {},
    };
  },
  methods: {

    rankListSlide() {
      let slider = document.querySelector('.rankListSlider');
      let slides = document.querySelectorAll('.rankListSlide');

      let slideHeight = slides[0].offsetHeight;
      let currentIndex = 0;

      function slideUp() {
        currentIndex++;
        if (currentIndex === slides.length) {
          currentIndex = 0;
          slider.style.transition = 'none'; // 去除过渡效果
          slider.style.transform = 'translateY(0)';
          setTimeout(() => {
            slider.style.transition = 'transform 1s ease-in-out'; // 恢复过渡效果
          }, 20); // 确保无缝切换
        } else {
          slider.style.transform = `translateY(-${currentIndex * slideHeight}px)`;
        }
      }

      setInterval(slideUp, 3000);
    },

    showLoginActionSheet(e) {
      this.$store.commit("showLoginActionSheet", e);
    },

    deWeight(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },

    async toGame(e) {
      let gameItem = this.getGameItem(e.gid);
      let arr = localStorage.getItem("7topgame.com-user-history");
      if (arr == null) {
        arr = [];
        arr.push(gameItem);
        localStorage.setItem("7topgame.com-user-history", JSON.stringify(arr));
      } else {
        let oldArr = JSON.parse(arr);
        oldArr.unshift(gameItem);
        let newArr = this.deWeight(oldArr);
        localStorage.setItem(
          "7topgame.com-user-history",
          JSON.stringify(newArr)
        );
      }

      // if (gameItem.isHot == '1') {
      //     let userInfo = JSON.parse(localStorage.getItem('7topgame.com-user-info'))
      //     if (userInfo.isTourist) {
      //         this.showLoginActionSheet(true)
      //         Toast(this.$t("common.userAction.cannotPlayGame"))
      //         return
      //     }
      // }

      api.openGame(gameItem.id);


      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });

      let userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));

      if (gameItem.gameUrl == '') {
        return
      }

      setTimeout(() => {
        let gameInfo = {
          gameUrl: gameItem.gameUrl,
          uid: userInfo.id,
          gid: gameItem.id,
        };
        this.changeIframeData(gameInfo);
        this.$router.push("/start/game");
      }, 500);

      // setTimeout(() => {
      //     window.open(gameItem.gameUrl, '_blank');
      // }, 500);
    },

    changeIframeData(e) {
      this.$store.commit("changeIframeData", e);
    },

    async findRankListByLang() {
      let l = localStorage.getItem("7topgame.com-user-lang");
      let sourceObj = JSON.parse(sessionStorage.getItem("7topgame.com-source"));
      let params = {
        lang: l,
        sourceId: sourceObj.id,
      };

      try {
        let axiosResult = await api.findRankListByLang(params);
        if (axiosResult.code == 10041) {
          this.rankListData = axiosResult.data;

          sessionStorage.setItem(
            "7topgame.com-rankList",
            JSON.stringify(axiosResult.data)
          );
          return;
        }
        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    getGameItem(gid) {
      let gameList = JSON.parse(
        sessionStorage.getItem("7topgame.com-game-list")
      );
      for (let i = 0; i < gameList.length; i++) {
        const ele = gameList[i];
        if (ele.id == gid) {
          return ele;
        }
      }
    },
  },
  async mounted() {
    await this.findRankListByLang();
    this.rankListSlide()
  },
};
</script>

<style scoped>
.rankListComponentBox {
  width: 100%;
  height: 30px;
  overflow: hidden;
  position: relative;
}

/* 内部滚动区域 */
.rankListSlider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 1s ease-in-out;
}

/* 每个滑动项 */
.rankListSlide {
  width: 100%;
  height: 100%;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  text-align: center;
  color: white;
}

.rankListBonusInfoItemText {
  margin: 0 auto;
  width: 100%;
  height: 31px;
  line-height: 31px;
  color: white;
  font-size: 11px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}

.rankListBonusInfoItemIcon {
  /* position: absolute;
    left: 3px;
    top: 5px; */
  width: 21px;
  height: 21px;
  overflow: hidden;
}
</style>
