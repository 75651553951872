// const HOST = 'http://localhost:10002'
const HOST = 'https://back.7topgame.com'
// const HOST = 'https://www.7topgame.com/pro-api' 

// const IMGURL = "/images/"
// const IMGURL = "https://www.7topgame.com/s3-public/"

// const WEBSOCKET = 'wss://www.7topgame.com/server/socket/connect/'
const WEBSOCKET = 'https://back.7topgame.com/server/socket/connect/'
// const WEBSOCKET = 'ws://localhost:10002/server/socket/connect/'
export default { HOST, WEBSOCKET }     