var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainBox isMenuBox",style:({
  // height: pageHeight,
  minHeight: '900px',
  width: _vm.pageWidth,
  backgroundColor: _vm.backColor,
  // backgroundImage: isMainHome ? gradient : ''
}),on:{"touchmove":_vm.pageMoveChange}},[_vm._l((_vm.adData),function(item){return (_vm.isMobile)?_c('div',{key:item.id},[_c('div',{staticClass:"wheeldiscPosition",style:({ left: item.positionX + 'px', top: item.positionY + 'px' }),attrs:{"data-adid":item.id},on:{"mousedown":_vm.moveEle,"click":function($event){return _vm.openAd(item)},"touchmove":_vm.onTouchMove,"touchend":_vm.onTouchEnd}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.image,"alt":"","srcset":""}})])]):_vm._e()}),_c('van-sticky',[_c('Navi',{ref:"Navi"})],1),_c('main',[_c('router-view')],1),_c('van-sticky',[_c('div',{staticClass:"loginTipPisition"},[_c('LoginTip')],1)]),_c('footer',{staticClass:"bottom"},[_c('Bottom')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }