<template>
  <div>
    <div v-if="!isByClassification" class="gameItemBoxNormal" @click="toGame">
      <div class="isCollectGameItem" @click="stopClickEvent">
        <img style="width: 100%" v-if="game.isCollect" src="../../assets/like-selected.png" alt="" @click="del" />
        <img style="width: 100%" v-else src="../../assets/like-no-select.png" alt="" @click="collect" />
      </div>
      <div v-if="game.isHot == '1'" class="iconTopNormal">
        {{ $t("common.home.hot") }}
      </div>
      <div style="width: 100%; height: 132px; overflow: hidden">
        <img class="gameImg" :src="game.image" alt="" />
      </div>
      <div class="backCover">
        <img src="../../assets/gameItemBack.png" style="width: 102%; height: 100%" alt="" />
        <div class="gameName">
          {{ game.gameName }}
        </div>
      </div>
    </div>

    <div v-else class="gameItemBoxMax" @click="toGame">
      <div class="isCollectGameItem" @click="stopClickEvent">
        <img style="width: 100%" v-if="game.isCollect" src="../../assets/like-selected.png" alt="" @click="del" />
        <img style="width: 100%" v-else src="../../assets/like-no-select.png" alt="" @click="collect" />
      </div>
      <div v-if="game.isHot == '1'" class="iconTopNormal">
        {{ $t("common.home.hot") }}
      </div>
      <div style="width: 100%; height: 117px; overflow: hidden">
        <img class="gameImg" :src="game.imageDetail" alt="" />
      </div>
      <div class="backCover">
        <img src="../../assets/gameItemBack.png" style="width: 102%; height: 100%" alt="" />
        <div class="gameName">
          {{ game.gameName }}
        </div>
      </div>
    </div>

    <van-action-sheet v-model="showGameInfo" style="background-color: #1c2028">
      <div class="gameItemContent">
        <div class="gameItemInfo">
          <img class="gameImg" :src="game.image" alt="" />
          <div class="gameItem">
            <p></p>
            <div class="gameItemName">
              {{ game.gameName }}
            </div>
            <div class="description">
              {{ $t("common.userAction.start") }}
            </div>
            <div class="played">
              {{ game.openNum }} {{ $t("common.userAction.played") }}
            </div>
          </div>
        </div>
        <div class="gameItemStart">
          <van-button icon="play-circle" style="font-size: 20px" color="#9338FA" type="info" block @click="toGame">{{
            $t("common.button.play") }}</van-button>
        </div>
        <div class="collect">
          <van-icon name="like-o" v-if="!game.isCollect" @click="collect" size="25px" />
          <van-icon name="like" v-else color="red" @click="del" size="25px" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapState, mapActions } from "vuex";
import { Toast } from "vant";
import api from "../../request/api";
export default {
  props: {
    game: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      show: false,
      showGameInfo: false,
      gameItem: {},
      userInfo: {},
      isByClassification: false,
    };
  },

  methods: {
    showLoginActionSheet(e) {
      this.$store.commit("showLoginActionSheet", e);
    },

    changeIframeData(e) {
      this.$store.commit("changeIframeData", e);
    },

    stopClickEvent() {
      event.stopImmediatePropagation();
    },

    async del() {
      if (this.userInfo.isTourist) {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          // this.$router.push("/login");
          this.showLoginActionSheet(true);
        }, 800);
        return;
      }
      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };

      try {
        let axiosResult = await api.delUserCollect(userCollect);
        if (axiosResult.code == 10021) {
          this.game.isCollect = false;
          let localUserCollect = JSON.parse(
            localStorage.getItem("7topgame.com-user-collect")
          );
          if (localUserCollect != null) {
            for (let i = 0; i < localUserCollect.length; i++) {
              const element = localUserCollect[i];
              if (userCollect.gameId == element.gameId) {
                localUserCollect.splice(i, 1);
                break;
              }
            }
          }
          localStorage.setItem(
            "7topgame.com-user-collect",
            JSON.stringify(localUserCollect)
          );
          Toast(this.$t("common.axios.successful"));
          let result = {
            collect: false,
            id: this.game.id,
          };
          this.changeFavoriteGameData(result);
          this.changeProfileRanklistData(result);
          this.changeAllGameListIsCollect(result);
          this.handleChangeGameDataByDetail(result);
          return;
        }

        this.game.isCollect = true;
        let data = {
          id: this.game.id,
          collect: true,
        };
        this.changeProfileRanklistData(data);
        this.resetHistoryLocalData(data);
        this.changeAllGameListIsCollect(data);
        this.handleChangeGameDataByDetail(data);
        // Toast(this.$t("common.axios.failed"));
      } catch (error) {
        // Toast(this.$t("common.axios.failed"));
      }
    },

    async collect() {
      if (this.userInfo.isTourist) {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          // this.$router.push("/login");
          this.showLoginActionSheet(true);
        }, 1500);
        return;
      }

      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };

      const analytics = getAnalytics();

      logEvent(analytics, "collect", {
        uid: this.userInfo.id,
        gid: this.game.id,
      });

      try {
        let axiosResult = await api.userCollectGame(userCollect);
        if (axiosResult.code == 10011) {
          this.game.isCollect = true;
          localStorage.setItem(
            "7topgame.com-user-collect",
            JSON.stringify(axiosResult.data)
          );
          Toast(this.$t("common.axios.successful"));
          let result = {
            collect: true,
            id: this.game.id,
          };
          this.changeFavoriteGameData(result);
          this.changeProfileRanklistData(result);
          this.changeAllGameListIsCollect(result);
          this.handleChangeGameDataByDetail(result);
          return;
        }
        this.game.isCollect = false;
        let data = {
          id: this.game.id,
          collect: false,
        };
        this.changeProfileRanklistData(data);
        this.resetHistoryLocalData(data);
        this.changeAllGameListIsCollect(data);
        this.handleChangeGameDataByDetail(data);
        // Toast(this.$t("common.axios.failed"));
      } catch (error) {
        // Toast(this.$t("common.axios.failed"));
      }
    },

    isCollectGame() {
      let collect = JSON.parse(
        localStorage.getItem("7topgame.com-user-collect")
      );
      if (collect == null) {
        return;
      }
      for (let i = 0; i < collect.length; i++) {
        const element = collect[i];
        if (element.gameId == this.game.id) {
          this.game.isCollect = true;
          break;
        } else {
          this.game.isCollect = false;
        }
      }
    },

    showGameInfoActionSheet() {
      this.showGameInfo = true;
    },

    deWeight(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },

    changeHistoryData(data) {
      this.$EventBus.$emit("changeHistoryData", data);
    },

    changeHistoryCollectData(data) {
      this.$EventBus.$emit("changeHistoryCollectData", data);
    },

    changeFavoriteGameData(data) {
      this.$EventBus.$emit("changeFavoriteGameData", data);
    },

    changeProfileRanklistData(data) {
      this.$EventBus.$emit("changeProfileRanklistData", data);
    },

    changeAllGameListIsCollect(data) {
      this.$EventBus.$emit("changeAllGameListIsCollect", data);
    },

    handleChangeGameDataByDetail(data) {
      this.$EventBus.$emit("handleChangeGameDataByDetail", data);
    },

    resetHistoryLocalData(data) {
      let historyData = JSON.parse(
        localStorage.getItem("7topgame.com-user-history")
      );

      for (let i = 0; i < historyData.length; i++) {
        if (data.id == historyData[i].id) {
          historyData[i].isCollect = data.collect;
        }
      }

      localStorage.setItem(
        "7topgame.com-user-history",
        JSON.stringify(historyData)
      );
      this.$EventBus.$emit("resetHistoryLocalData", historyData);
    },

    toGame() {
      const analytics = getAnalytics();
      logEvent(analytics, "open_game", {
        // content_type: 'link',
        game_name: this.game.gameName,
      });

      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });

      api.openGame(this.game.id);

      let arr = JSON.parse(localStorage.getItem("7topgame.com-user-history"));
      let newArr = [];
      if (arr == null) {
        arr = [];
        arr.push(this.game);
        localStorage.setItem("7topgame.com-user-history", JSON.stringify(arr));
        newArr = arr;
      } else {
        arr.unshift(this.game);
        newArr = this.deWeight(arr);
        localStorage.setItem(
          "7topgame.com-user-history",
          JSON.stringify(newArr)
        );
      }

      this.changeHistoryData(newArr);

      setTimeout(() => {
        // window.open(this.game.gameUrl, "_blank");
        let gameInfo = {
          gameUrl: this.game.gameUrl,
          uid: this.userInfo.id,
          gid: this.game.id,
        };
        this.changeIframeData(gameInfo);
        this.$router.push("/start/game");
        Toast.clear();
      }, 500);
    },

    changeGameItemWidth() {
      let queryParams = this.$route.query.classification;

      if (queryParams == undefined) {
        this.isByClassification = false;
        return;
      }
      this.isByClassification = true;
    },
  },

  components: {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    this.changeGameItemWidth();
  },
};
</script>

<style scoped>
.gameItemBoxMin {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.gameItemBoxNormal {
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.gameItemBoxNormal .isCollectGameItem {
  position: absolute;
  top: 3%;
  right: 3%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.gameItemBoxMax {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.gameItemBoxMax .isCollectGameItem {
  position: absolute;
  top: 2%;
  right: 5%;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  z-index: 1;
}

.gameItemBoxPc .gameName {
  font-size: 18px;
  line-height: 50px;
}

.iconTopNormal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: orange;
  color: white;
  font-weight: bold;
  z-index: 1;
  background-image: linear-gradient(to bottom, #9338fa, #c000f4 100%);
}

.iconTopMin {
  position: absolute;
  top: 0px;
  left: 4px;
  width: 40px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: orange;
  color: white;
  font-weight: bold;
  /* z-index: -1; */
  background-image: linear-gradient(to bottom, #9338fa, #c000f4 100%);
}

.gameItemContent {
  padding: 16px 16px 40px 16px;
  position: relative;
}

.gameItemInfo {
  padding: 10px 0;
  display: flex;
  margin: 20px auto;
  margin-left: 20px;
  width: 93%;
  height: 120px;
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
}

.gameItem {
  flex: 1.3;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gameItemStart {
  margin: 0px auto;
  width: 95%;
  border-radius: 6px;
  overflow: hidden;
}

.description {
  flex: 1;
  width: 100%;
  height: 30%;
  margin-top: 10px;
  font-size: 14px;
  word-wrap: break-word;
  /* 允许在单词内部换行 */
  white-space: wrap;
  color: rgb(219, 224, 207);
}

.gameItemName {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.played {
  flex: 1;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  /* overflow: hidden; */
  color: rgb(219, 224, 207);
}

.collect {
  position: absolute;
  right: 5%;
  top: 16%;
  /* background-color: red; */
  width: 30px;
  height: 30px;
}

.backCover {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 100%;
  height: 55px;
}

/* 背景渐变色模糊 虚化 */
/* .backCover {
  position: absolute;
  bottom: -2px;
  left: -3px;
  width: 106%;
  height: 35px;
}

.backCover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to top, rgb(0, 0, 0, 1), rgb(0, 0, 0, 0.8) 80%);
  filter: blur(2px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
} */

.gameName {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 40px;
  color: white;
  font-size: 12px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  /* 文字溢出隐藏，和overflow配合使用 */
  text-overflow: ellipsis;
}

.gameImg {
  width: 100%;
  height: 100%;
  border-radius: 8.5%;
}
</style>
