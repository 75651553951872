<template>
  <div style="z-index: 888">
    <div class="classifyBox2">
      <div class="leftArea2" style="
          /* margin-bottom: 10px; */
          height: 25px;
          /* line-height: 30px; */
          display: flex;
          align-items: left;
          justify-content: left;
          color: #D4AFFC;
          font-weight: bold;
        ">
        <!-- <van-icon name="fire" /> -->
        <img class="iconItem2" :src="classification.icon" alt="" />
        {{ classification.name }}
      </div>
      <div class="rightArea2">
        <div class="moreBtn2" @click="toAllGames()">
          <!-- <span>{{ $t("common.home.more") }}</span> -->
          <!-- <img class="moreBtn2Play" src="../../assets/moreBtnPlay.png" alt=""> -->
          <img class="moreBtn2Play" src="../../assets/moreBtnPlay.png" alt="">
        </div>
        <!-- <van-button  size="mini" icon="arrow-left" style="background-color: #1d2027; border: none;" />
                <van-button  size="mini" icon="arrow" style="background-color: #1d2027; border: none;" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classification: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {};
  },
  methods: {
    toAllGames() {
      this.$router.push({
        path: "/all/games",
        query: { classification: this.classification.name },
      });
    },
  },
  mounted() { },
};
</script>

<style scoped>
.classifyBox2 {
  display: flex;
  /* justify-content: center; */
  margin: 4px auto;
  margin-top: 7px;
  margin-bottom: 13px;
  width: 91%;
  z-index: 10;
  /* height: 35px; */
  /* border: 1px solid #ccc; */
}

.classifyBox2 div {
  /* margin: 0 17px; */
  /* line-height: 35px; */
  /* text-indent: 1rem; */
  color: white;
  /* background-color: pink; */
}

.rightArea2 {
  flex: 0.7;
  text-align: right;
}

.leftArea2 {
  flex: 1.3;
  padding-bottom: 1px;
  font-size: 14px;
  line-height: 28px;
}

.moreBtn2 {
  /* position: relative; */
  float: right;
  /* margin-top: 1px; */
  width: 56px;
  max-height: 28px;
  font-size: 11px;
  background-color: #292f35;
  border-radius: 4px;
  overflow: hidden;
  /* opacity: 0.9;
  color: #acaeb1;
  border: none;
  text-align: right; */
}

/* .moreBtn2:after {
  content: '';
  position: absolute;
  right: 15%;
  top: 55%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-image: url('../../assets/moreBtn2Play.png');
  background-size: cover;
} */

/* .moreBtn2Play {
  float: right;
  margin-top: 12%;
  margin-right: 20%;
  width: 10px;
  height: 10px;
} */

.moreBtn2Play {
  width: 100%;
}

.iconItem2 {
  float: left;
  margin-right: 10px;
  margin-top: 2px;
  width: 25px;
  /* height: 25px; */
}
</style>
