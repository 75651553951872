<template>
    <div ref="animationContainer">

    </div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '../../assets/wheeldisc/wheeldisc.json';
export default {
    name: 'LottieAnimation',
    mounted() {
        this.loadAnimation();
    },
    methods: {
        loadAnimation() {
            lottie.loadAnimation({
                container: this.$refs.animationContainer, // 动画要挂载的DOM元素
                renderer: 'svg', // 使用SVG渲染
                loop: true, // 循环播放
                autoplay: true, // 自动播放
                animationData: animationData, // 传入Lottie JSON动画数据
                assetsPath: process.env.BASE_URL + 'wheeldisc/images/',
            });
        }
    }
}


</script>

<style scoped>
#animationContainer {
    width: 100%;
    height: 100%;
}
</style>