//中文语言包zh.js

export default {
    common: {
        login: {
            bannerWord: '欢迎登陆',
            account: '邮箱',
            verifyCode: '验证码',
            sendCode: "发送",
            password: "密码",
            signInWithPassword: '通过密码登陆',
            signInWithEmail: '通过邮箱登陆',
            signIn: '一键登录/注册',
            signInWithTourist: '通过游客登陆',
            signInWithGoogle: '通过谷歌登录',
            inputTip: '输入你的昵称（选填）'
        },
        register: {
            bannerWord: '欢迎注册',
            account: '邮箱',
            verifyCode: '验证码',
            sendCode: "发送",
            password: "密码",
            signInWithPassword: '通过密码注册',
            signInWithEmail: '通过邮箱注册',
            signUp: '注册',
        },
        home: {
            signIn: '登录',
            signUp: '注册',
            recommend: '推荐',
            more: '更多',
            hot: '热门',
            new: '最新',
            search: '搜索',
            result: '搜索结果',
            enterGameName: '输入游戏名',
            searchLimit: '至少搜索三个字符!',
            history: '浏览历史',
            congratulations: '恭喜用户',
            winning: '赢了',
            in: '在',
            artificial: '人工',
            language: '语言',
            addTopDesktopContent: '为了方便使用这个游戏平台，您是否同意将其安全地添加到您的桌面?',
        },
        profile: {
            favorite: "我的喜欢",
            history: "浏览历史",
            languages: "语言",
            nickname: '昵称',
            gender: '性别',
            confirm: '确认',
            cancer: '取消',
            nameIsNull: '空名字',
            Logout: '退出',
            enterName: '输入名字',
            enterGender: '输入性别',
            sort: '第',
            profileClassificationLike: '我的喜欢',
            profileClassificationHistory: '历史记录',
            profileClassificationGuess: '猜你喜欢',
            playMore: '玩得越多',
            winMore: '赢得越多',
            switchAccount: '更换账号',
            rankList: "每周热榜",
            email: "邮箱:",
            setName: '设置名字',
            notSupportChangeName: '游客不支持改名',
        },
        bottom: {
            home: '主页',
            profile: '我的',
            noMore: '没有更多了~',
        },
        userAction: {
            favorite: '我的喜欢',
            history: '浏览历史',
            played: ' 人玩过',
            start: '点击开始游戏吧！',
            cannotPlayGame: '你不能玩这个游戏',
            installed: "祝贺，你已经将此软件安装到桌面"
        },
        button: {
            play: '开始',
            cancer: '取消',
            confirm: '确认',
            addTopDesktop: '添加到桌面',
        },
        // 登录提示
        loginTip: {
            signInToPlayAllGames: "登录开启全部游戏",
            signInNow: '现在登陆',
            selectAcount: '选择账号登陆',
            signInWithEmail: '通过邮箱登录',
            signInWithGoogle: '通过谷歌登录',
            signInWithFB: '通过脸书登录',
            emailAccountLogin: '邮箱登录',
            sendCheckCode: '发送验证码',
            enterEmail: '输入邮箱',
            enterCheckCode: '输入验证码',
            notSupportEmail: '不支持的邮箱格式',
        },
        //  请求结果通知
        axios: {
            signInSuccessful: "你已经登录/注册成功",
            collectSuccess: '收藏成功',
            cancerCollect: '取消收藏',
            noData: '没有数据',
            replication: '复制成功',
            successful: '操作成功',
            failed: '请求失败',
            noLogin: "你还没有登录",
            choosePhoto: '请先选择图片',
            fileToLarge: '图片不能大于1M',
            fileTypeNoSupports: '文件类型不支持',
            identity: '当前身份：游客',
            accountIsNotExisting: '账号不存在',
            accountDisable: "你已经被禁止登录",
            frequently: "你的操作太快了",
            adLoadFailed: '广告加载失败',
        }
    }
}