<template>
    <div>
        <div class="game-swiper" v-if="isMobile">
            <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" style="max-height: 168px;">
                <!--eslint-disable-next-line-->
                <van-swipe-item v-for="item in slideshowData" v-bind:key="item.id">
                    <img class="bg" :src="item.image" @click="showGameInfoActionSheet(item)" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import api from '../../request/api';
export default {
    data() {
        return {
            slideshowData: [],
            game: {},
            isMobile: true,
        }
    },
    methods: {

        showLoginActionSheet(e) {
            this.$store.commit("showLoginActionSheet", e);
        },

        async findByStatus() {
            let sourceObj = JSON.parse(sessionStorage.getItem('7topgame.com-source'))
            try {
                let axiosResult = await api.getSlideshowByStatus(sourceObj.id)
                if (axiosResult.code == 10041) {
                    this.slideshowData = axiosResult.data
                    return
                }
                Toast(this.$t("common.axios.failed"));
            } catch (error) {
                Toast(this.$t("common.axios.failed"));
            }
        },

        showGameInfoActionSheet(item) {

            if (item.url == '') {
                return
            }

            Toast.loading({
                forbidClick: true,
                loadingType: "spinner",
            });

            api.openGame(item.id)

            let userInfo = JSON.parse(localStorage.getItem('7topgame.com-user-info'))

            if (item.url == '') {
                return
            }

            setTimeout(() => {
                let gameInfo = {
                    gameUrl: item.url,
                    uid: userInfo.id,
                    gid: item.id,
                };
                Toast.clear()
                this.changeIframeData(gameInfo);
                this.$router.push("/start/game");
            }, 500);


        },

        changeIframeData(e) {
            this.$store.commit("changeIframeData", e);
        },

        toGame() {
        },

        handleCurrentPageChange() {
            let width = window.innerWidth;
            if (width > 550) {
                this.isMobile = false
            } else {
                this.isMobile = true
            }
        },

    },
    mounted() {

        this.findByStatus()

        this.handleCurrentPageChange()

        window.addEventListener('resize', () => {
            this.handleCurrentPageChange()
        });

    }
}
</script>

<style scoped>
.game-swiper {
    margin: 5px auto;
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
}

.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    max-height: 180px;
    text-align: center;
    overflow: hidden;
}

.my-swipe .van-swipe-itemPc {
    color: #fff;
    font-size: 20px;
    max-height: 480px;
    text-align: center;
}

.bg {
    width: 100%;
    /* height: 100%; */
    min-height: 169px;
}

.content {
    padding: 16px 16px 40px 16px;
}

.gameInfo {
    display: flex;
    margin: 10px auto;
    width: 94%;
    height: 120px;
    /* background-color: aqua; */
}

.gameImg {
    flex: 0.6;
    background-color: black;
}

.info {
    flex: 1.4;
}

.gameStart {
    margin: 0px auto;
    width: 94%;
    border-radius: 6px;
    overflow: hidden;
    /* background-color: pink; */
}
</style>
