var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: _vm.pageHeight,
    width: _vm.pageWidth,
    // minHeight: '900px',
    margin: '0 auto',
    // backgroundColor: '#472546',
    background: 'radial-gradient(circle, #a21b80,#080d1b)'
})},[_c('div',{staticClass:"gameIframe"},[_c('iframe',{staticClass:"gameIframe",attrs:{"src":_vm.startGameInfo.gameUrl,"width":_vm.pageWidth,"height":_vm.pageHeight,"frameborder":"0px","scrolling":"auto"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }