<template>
    <div :style="{
        height: pageHeight,
        // minHeight:'900px',
        width: pageWidth,
        margin: '0 auto',
        // backgroundColor: 'white',
        background: 'radial-gradient(circle, #a21b80,#080d1b)'
    }">

        <div class="adIframe">
            <iframe :src="adInfo.url" :width="pageWidth" :height="pageHeight" frameborder="0px" scrolling="auto">
            </iframe>
        </div>

        <!-- <div :class="adInfo.closeBtnPosition == 1 ? 'closeWindowBtnRight' : 'closeWindowBtnLeft'" @click="closeWindows">
            <van-icon size="25" name="clear" color="#8c8d8f" />
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageHeight: '',
            pageWidth: '',
            startTime: null,
            endTime: null,
            closeAdButtonIsShow: false,
            playAdRecord: {},
            adInfo: {}
        }
    },
    methods: {
        closeWindows() {
            let adInfo = null
            this.$store.commit("changeAdIframeData", adInfo);
            this.$router.go(-1)
        },

        handleCurrentPageChange() {
            let height = window.innerHeight;
            let width = window.innerWidth;
            this.pageHeight = height + "px";
            this.pageWidth = width + "px";
        },

    },

    beforeDestroy() {
        // this.$router.go(-1)
    },

    mounted() {

        if (this.$store.state.adIframeInfo == null) {
            this.$router.go(-1)
            return
        }

        this.adInfo = this.$store.state.adIframeInfo

        setTimeout(() => {
            this.closeAdButtonIsShow = true
        }, this.$store.state.adIframeInfo.closeBtnShowTime);

        this.handleCurrentPageChange()

        window.addEventListener('resize', () => {
            this.handleCurrentPageChange()
        });
    },
}
</script>

<style scoped>
.adIframe {
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* .adIframe::-webkit-scrollbar {
    display: none;
} */

.closeWindowBtnRight {
    position: absolute;
    top: 10px;
    right: 15px;
}

.closeWindowBtnLeft {
    position: absolute;
    top: 10px;
    left: 15px;
}
</style>
