<template>
  <!-- <div :class="isMainHome ? 'mainBox isMenuBox' : 'mainBox'" :style="{ -->
  <div class="mainBox isMenuBox" :style="{
    // height: pageHeight,
    minHeight: '900px',
    width: pageWidth,
    backgroundColor: backColor,
    // backgroundImage: isMainHome ? gradient : ''
  }" @touchmove="pageMoveChange">
    <!-- <div class="wheeldiscPosition" :style="{ left: position.x + 'px', top: position.y + 'px' }" @mousedown="moveEle"
      @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
      <WheelDisc></WheelDisc>
    </div> -->

    <!-- :data-adId="item.id" 只能存字符串，可用JSON.stringify() -->
    <div v-if="isMobile" v-for="item in adData" v-bind:key="item.id">
      <div class="wheeldiscPosition" :style="{ left: item.positionX + 'px', top: item.positionY + 'px' }"
        @mousedown="moveEle" :data-adid="item.id" @click="openAd(item)" @touchmove="onTouchMove" @touchend="onTouchEnd">
        <img style="width: 100%" :src="item.image" alt="" srcset="" />
        <!-- <div class="closeAd">
          <img src="../assets/exit.png" alt="">
        </div> -->
      </div>
    </div>

    <van-sticky>
      <Navi ref="Navi"></Navi>
    </van-sticky>

    <main>
      <router-view></router-view>
    </main>

    <van-sticky>
      <div class="loginTipPisition">
        <LoginTip></LoginTip>
      </div>
    </van-sticky>

    <footer class="bottom">
      <Bottom></Bottom>
    </footer>
  </div>
</template>

<script>
import Navi from "../views/Navi";
import Bottom from "../components/bottom/Bottom.vue";
import LoginTip from "../components/logintip/LoginTip.vue";
import WheelDisc from "../components/wheeldisc/WheelDisc.vue";
import { mapState, mapActions } from "vuex";
import { Toast } from "vant";
import api from "../request/api";
export default {
  data() {
    return {
      accessId: "",
      orderedDetail: {},
      seeOrderedDialog: false,
      isMainHome: false,
      isLoginActionSheet: true,
      isMobile: true,
      pageHeight: "",
      pageWidth: "",
      backColor: "#060c18",
      // gradient: 'linear-gradient(to bottom left, #a21b80, #080d1b 60%)',
      beforeinstallprompt: null,
      adData: [],
      source: {},
    };
  },

  computed: {
    ...mapState({
      count: (state) => state.isLoginActionSheet,
    }),
  },

  // 监听器
  watch: {
    $route: function (to, from) {
      this.isShowGradient();
    },
  },

  methods: {

    pageMoveChange() {
      this.pageHeight = window.innerHeight
      this.pageWidth = window.innerWidth
    },

    openAd(e) {
      let url = "";
      if (e.enableByUuid == "1") {
        const { v4: uuidv4 } = require("uuid");
        const uuid = uuidv4();
        url = e.url + uuid;
      } else {
        url = e.url;
      }
      let adInfo = {
        url: url,
        id: e.id,
        closeBtnPosition: e.closeBtnPosition,
        closeBtnShowTime: e.closeBtnShowTime,
      };
      this.$store.commit("changeAdIframeData", adInfo);
      this.$router.push("/start/ad");
    },

    showLoginOptionActionSheet(e) {
      this.$store.commit("showLoginActionSheet", e);
    },

    onTouchStart(event) { },

    onTouchMove(event) {
      let adList = this.adData;
      for (let i = 0; i < adList.length; i++) {
        if (event.currentTarget.dataset.adid == adList[i].id) {
          if (event.touches.length === 1) {
            let maxX = window.innerWidth - 50;
            let maxY = window.innerHeight - 150;
            let newX = event.touches[0].clientX - 50;
            let newY = event.touches[0].clientY - 50;
            if (newY < 50) newY = 50;
            if (newY > maxY) newY = window.innerHeight - 150;
            adList[i].positionX = newX;
            adList[i].positionY = newY;
          }
          break;
        }
      }
    },

    onTouchEnd(event) {
      let adList = this.adData;
      for (let i = 0; i < adList.length; i++) {
        if (event.currentTarget.dataset.adid == adList[i].id) {
          let timerId = setInterval(() => {
            let maxX = window.innerWidth - 100;
            let minX = -0;
            let weltX = Math.floor(maxX / 2);
            if (adList[i].positionX >= weltX) {
              if (adList[i].positionX > maxX) {
                adList[i].positionX = maxX;
                clearInterval(timerId);
              }
              adList[i].positionX += 3;
            } else {
              if (adList[i].positionX < minX) {
                adList[i].positionX = minX;
                clearInterval(timerId);
              }
              adList[i].positionX -= 3;
            }
          }, 5);
          break;
        }
      }
    },

    moveEle() {
      const box1 = document.querySelector(".wheeldiscPosition");
      box1.onmousedown = (e) => {
        const X = e.clientX - box1.offsetLeft;
        const Y = e.clientY - box1.offsetTop;
        const maxWidth = document.body.clientWidth - box1.offsetWidth;
        const maxHeight =
          document.documentElement.clientHeight - box1.offsetHeight;
        document.onmousemove = (event) => {
          event = event || window.event;
          let left = event.clientX - X;
          let top = event.clientY - Y;
          if (left < 0) {
            left = 0;
          } else if (left > maxWidth) {
            left = maxWidth;
          }
          if (top < 0) {
            top = 0;
          } else if (top > maxHeight) {
            top = maxHeight;
          }
          box1.style.left = left + "px";
          box1.style.top = top + "px";
        };
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    },

    changeLoginStatus(e) {
      this.$store.commit("changeLoginStatus", e);
    },

    async checkGoogleIsLogin() {
      let fullPath = this.$route.fullPath;
      let beginIndex = fullPath.lastIndexOf("?");
      let endIndex = fullPath.indexOf("=");

      if (beginIndex == -1 || endIndex == -1) {
        return;
      }
      let isSuccess = fullPath.slice(beginIndex + 1, endIndex);

      if (isSuccess == "error") {
        // Toast('')
        return;
      }

      let encryptCode = fullPath.slice(endIndex + 1);
      // let email = string.replace(/%40/g, '@');
      // NFZK4irEew7yTbN6Q%205VYw%3D%3D
      try {
        let axiosResult = await api.checkGoogleIsLogin(encryptCode);
        if (axiosResult.code == 10041) {
          axiosResult.data.isTourist = false;
          localStorage.setItem(
            "7topgame.com-user-info",
            JSON.stringify(axiosResult.data)
          );
          Toast(this.$t("common.axios.signInSuccessful"));
          this.changeLoginStatus(true);
          this.showLoginOptionActionSheet(false);
          this.$router.push("/");
          return;
        }
        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    async generateTourist() {
      let tourist = {
        id: null,
        gender: "",
        name: "",
        avatar: "",
      };

      try {
        let axiosResult = await api.generateTourist(tourist);
        if (axiosResult.code == 10011) {
          Toast(this.$t("common.axios.identity"));
          axiosResult.data.isTourist = true;
          localStorage.setItem(
            "7topgame.com-user-info",
            JSON.stringify(axiosResult.data)
          );
          this.changeLoginStatus(false);
        }
      } catch (error) { }
    },

    isShowGradient() {
      if (this.$route.path.includes("/profile")) {
        this.isMainHome = false;
      }
      if (this.$route.path.includes("/home")) {
        this.isMainHome = true;
      }
    },

    visitSite() {
      let userInfo = localStorage.getItem("7topgame.com-user-info");
      let tid = userInfo == "{}" ? 0 : JSON.parse(userInfo).id;
      api.visitSite(tid);
    },

    async getAds() {
      let adSource = JSON.parse(sessionStorage.getItem("7topgame.com-source"));

      if (adSource == null) {
        await this.initSource();
        adSource = JSON.parse(sessionStorage.getItem("7topgame.com-source"));
        // return
      }

      try {
        let axiosResult = await api.getAd(adSource.id);
        if (axiosResult.code == 10041) {
          this.handleAdPisotionChange(axiosResult.data);
          return;
        }
        // Toast(this.$t("common.axios.adLoadFailed"));
      } catch (error) {
        // Toast(this.$t("common.axios.adLoadFailed"));
      }
    },

    handleAdPisotionChange(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].positionX == 999) {
          arr[i].positionX = window.innerWidth - 100;
        }
        if (arr[i].positionX == -999) {
          arr[i].positionX = 0;
        }
      }
      this.adData = arr;
    },

    async fbSignIn() {
      this.$axios
        .get(
          "https://graph.facebook.com/oauth/access_token?client_id=421990884296462&client_secret=1efdf904170a64c1372c265d900f8f59&grant_type=client_credentials"
        )
        .then((res) => {
          this.$axios
            .get(
              // "https://graph.facebook.com/v21.0/me?fields=id,name,email&access_token=" + res.data.access_token + '&appsecret_proof=1efdf904170a64c1372c265d900f8f59'
              "https://graph.facebook.com/me?access_token=" +
              res.data.access_token
            )
            .then((res) => { });
        })
        .catch((err) => {
          Toast(err);
        });
    },

    async initSource() {
      let isHaveData = JSON.parse(
        sessionStorage.getItem("7topgame.com-source")
      );
      if (isHaveData != null) {
        return;
      }

      try {
        let axiosResult = await api.initializeSource();
        if (axiosResult.code == 10041) {
          // this.$router.push(res.data.data.data[0].routerPath)
          let source = null;
          for (let i = 0; i < axiosResult.data.length; i++) {
            if (axiosResult.data[i].routerPath == this.$route.path) {
              source = axiosResult.data[i];
              break;
            }
          }
          this.source = source == null ? axiosResult.data[0] : source;
          sessionStorage.setItem(
            "7topgame.com-source",
            JSON.stringify(
              JSON.stringify(source) == "{}" ? axiosResult.data[0] : source
            )
          );
          return;
        }
        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    isMobileDevice() {
      if (window.innerWidth > 550) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    },

  },

  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));

    if (userInfo == null || JSON.stringify(userInfo) == "{}") {
      this.generateTourist();
    }

    if (userInfo.isTourist) {
      this.changeLoginStatus(false);
    } else {
      this.changeLoginStatus(true);
    }

    this.visitSite();

    this.checkGoogleIsLogin();

    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register("/serviceworker.js");
    // }

    // this.fbSignIn()

    this.getAds();

    window.addEventListener("resize", () => {
      this.isMobileDevice()
    });
  },

  components: {
    Navi,
    Bottom,
    LoginTip,
    WheelDisc,
  },
};
</script>

<style scoped>
.el-header {
  padding: 0;
  margin: 0;
}

.mainPage {
  padding: 1px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 2;
}

.mainBox {
  position: relative;
  overflow: hidden;
  /* background-image: url('https://s3.us-east-2.amazonaws.com/slotspots777.click/7topgame/cover/bees-love-synthesis.png'); */
  /* background: radial-gradient(circle, #a21b80,#080d1b); */
  /* background-image: linear-gradient(to bottom, #3d6d2d , #2452d3 100%); */
}

.isMenuBox {
  /* background-image: url('https://s3.us-east-2.amazonaws.com/slotspots777.click/7topgame/cover/bees-love-synthesis.png'); */
  background-image: url("../assets/mainBackimg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.fixedBox {
  position: sticky;
  top: 1px;
  height: 56px;
}

.loginPageImg {
  margin: 0 auto;
  margin-top: 13px;
  width: 91%;
  height: 140px;
  background-color: #100c1e;
  border-radius: 12px;
  overflow: hidden;
}

.loginMethodBox {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 70%;
  height: 160px;
}

.loginMethodBox .loginMethodItem {
  width: 240px;
  min-height: 42px;
}

.loginMethodItem .van-button--info {
  background-color: #c520d9;
  border: 1px solid #c520d9;
}

.loginMethodBox .van-divider {
  margin-bottom: 0;
}

.loginTipPisition {
  position: fixed;
  bottom: 77px;
  width: 100%;
  height: 35px;
  z-index: 3;
}

.wheeldiscPosition {
  position: fixed;
  width: 100px;
  height: 100px;
  z-index: 66;
  touch-action: none;
}

.iframeAdWindows {
  position: relative;
  position: absolute;
  top: 0px;
  margin: 0 auto;
  background-color: rgba(3, 3, 3, 0.4);
  z-index: 99;
}

.closeAd {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.closeAd img {
  width: 100%;
}
</style>
