<template>
    <div>
        <div class="rankListBoxItem" v-for="(item, index) in rankList" v-bind:key="item.id">
            <div class="rankListBonusInfoItemIcon">
                <img style="width: 100%;" v-if="index == 0" src="../../assets/hat1.png" alt="">
                <img style="width: 100%;" v-if="index == 1" src="../../assets/hat2.png" alt="">
                <img style="width: 100%;" v-if="index == 2" src="../../assets/hat3.png" alt="">
                <img style="width: 100%;" v-if="index == 3" src="../../assets/hat4.png" alt="">
            </div>
            <div class="rankListBonusInfoItemIconSort">
                <img style="width: 100%;" v-if="index == 0" src="../../assets/No.1.png" alt="">
                <img style="width: 100%;" v-if="index == 1" src="../../assets/No.2.png" alt="">
                <img style="width: 100%;" v-if="index == 2" src="../../assets/No.3.png" alt="">
                <img style="width: 100%;" v-if="index == 3" src="../../assets/No.4.png" alt="">
            </div>
            <div class="rankListBonusInfoItemText">
                <!-- {{ $t("common.profile.sort") }}{{ index + 1 }} -->
                {{ $t("common.home.congratulations") }}
                <span style="color: #e931e0;"> {{ item.username }}</span>
                <span style="color: #26bf01;"> {{ item.description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    props: {
        rankList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            rankListData: [],
        }
    },
    methods: {

    },
    mounted() {
    },
}
</script>

<style scoped>
.rankListBoxItem {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 3px;
    background-image: url("../../assets/rankListImg.png");
    background-size: cover;
    background-position: center;
}

.rankListBonusInfoItemText {
    margin: 0 auto;
    width: 95%;
    height: 20px;
    line-height: 20px;
    color: white;
    font-size: 8px;
    text-align: left;
    text-indent: 1.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.rankListBonusInfoItemIcon {
    position: absolute;
    top: -3px;
    width: 11px;
    overflow: hidden;
}

.rankListBonusInfoItemIconSort {
    position: absolute;
    bottom: -3px;
    left: 1px;
    width: 11px;
    overflow: hidden;
}
</style>