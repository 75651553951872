var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: _vm.pageHeight,
    // minHeight:'900px',
    width: _vm.pageWidth,
    margin: '0 auto',
    // backgroundColor: 'white',
    background: 'radial-gradient(circle, #a21b80,#080d1b)'
})},[_c('div',{staticClass:"adIframe"},[_c('iframe',{attrs:{"src":_vm.adInfo.url,"width":_vm.pageWidth,"height":_vm.pageHeight,"frameborder":"0px","scrolling":"auto"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }